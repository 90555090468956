import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => (
  <Layout>
    <SEO title="Congratulations" />
    <section class="hero is-large is-light is-bold">
      <div class="hero-body">
        <div class="container">
        <p class="is-size-2">Your pre-registration was successful!</p>
        <p class="is-size-7">We look forward to seeing you on March 28th! Please email any questions to bodysolutionzsm@gmail.com.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default ThankYou
